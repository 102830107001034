import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'

const texts = {
  right: {
    link: 'or book a meeting',
    pretitle: 'Goodbye legacy',
    text:
      'We’ve told you, our clients told you, now see for yourself. Get a live demo of the Ohpen platform right now.',
    title: "let's talk about the future",
    url: '/home/followup',
  },
}

const DemoPage: React.FC = () => (
  <Page
    content={
      <PageWrapper>
        <WithSlideShowCheck
          mobileContent={[<Text isMobile {...texts.right} />]}
        >
          <Box row>
            <Box col={0.6} />
            <Box col={0.4}>
              <Text {...texts.right} />
            </Box>
          </Box>
        </WithSlideShowCheck>
      </PageWrapper>
    }
    settings={{
      backgroundColor: '#113f4f',
      contentAnimationVariants: pageFadeInOut(),
      useCircularWipe: false,
    }}
  />
)

export default DemoPage
